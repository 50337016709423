<template>
    <b-modal :title="$t('config.logos.logo')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <img :src="`/api/client/v1/logos/${logoId}/500`"
                     style="max-width: 100%;display: block; margin-left: auto; margin-right: auto;" class="rounded-lg" alt="Image"/>
            </b-form-group>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="modalActive=false">
                <span>
                    <span class="align-middle">{{ $t('general.close') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal } from 'bootstrap-vue'
    export default {
        components: {
            BFormGroup,
            BModal,
            BButton
        },
        data() {
            return {
                logoId: '',
                modalActive: false
            }
        },
        methods: {
            open(data = null) {
                this.logoId = data
                this.modalActive = true
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>