<template>
    <b-modal size="xl" :title="$t('config.logos.logo')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group :label="$t('form_data.name')">
                <Table class="mt-1" :columnDefs="columnDefs" :rowData="logos" @confirm="selectLogo" @editRow="previewLogo"/>
            </b-form-group>
            <previewLogo v-model="previewModalActive" ref="previewModal"  @logoAdded="loadData" v-on:itemAdded="loadData"/>
        </template>
    </b-modal>
</template>
<script>
    import {BFormGroup, BModal} from 'bootstrap-vue'
    import Table from '@/views/components/Table/Table'
    import previewLogo from '@/views/Logo/PreviewLogo.vue'


    export default {
        components: {
            previewLogo,
            Table,
            BFormGroup,
            BModal
        },
        data() {
            return {
                columnDefs: [
                    { headerName: this.$t('table_fields.filename'), field: 'filename', filter: true, minWidth: 200},
                    { headerName: this.$t('table_fields.name'), field: 'display_name', filter: true},
                    { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'chooseLogo' } }}
                ],
                modalActive: false,
                logos: [],
                previewModalActive: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/logos/')
                    this.logos = response.data ?? []
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            previewLogo(data) {
                this.previewModalActive = true
                this.$refs.previewModal.open(data.id)
            },
            selectLogo(id) {
                this.$emit('addLogo', id)
                this.modalActive = false
            },
            open(data = null) {
                this.loadData()
                if (data !== null) {
                    this.addObject = data
                } else {
                    this.addObject = {
                        name: '',
                        business_unit_type: '',
                        street: '',
                        cadastral_number: null,
                        building_number: null,
                        building_section_number: null,
                        house_number: '',
                        house_number_additional: '',
                        community: '',
                        postal_code: '',
                        city: '',
                        business_unit_code: '',
                        enable_closing_time:false,
                        closing_time: new Date(1970, 1, 1),
                        warehouse_id:'',
                        printer_footer: '',
                        printer_header: '',
                        logo_id:''
                    }
                }
                this.modalActive = true
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>